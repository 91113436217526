import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PartnersPage = () => {
  let data = useStaticQuery(graphql`
    query PartnersPageQuery {
      prismicPartnersPage {
        data {
          hero_title {
            text
            html
          }
          hero_subtitle {
            text
            html
          }
          hero_image {
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
          strategic_alliances_headline {
            text
            html
          }
          strategic_alliances_description {
            text
            html
          }
          strategic_alliances_partners {
            partner_logo {
              fluid(maxHeight: 50) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            partner_description {
              text
              html
            }
            partner_name {
              html
              text
            }
          }
          technology_partners_headline {
            html
            text
          }
          technology_partners_description {
            html
            text
          }
          technology_partners {
            partner_logo {
              fluid(maxHeight: 50) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            partner_description {
              text
              html
            }
            partner_name {
              html
              text
            }
          }
        }
      }
    }
  `)
  if (!data || !data.prismicPartnersPage) return null
  data = data.prismicPartnersPage.data

  if (typeof window !== 'undefined' && window.__PRISMIC_PREVIEW_DATA__ && window.__PRISMIC_PREVIEW_DATA__.prismicPartnersPage) {
    data = window.__PRISMIC_PREVIEW_DATA__.prismicPartnersPage.data
  }

  return (
    <Layout mainClass="partners-page">
      <SEO title="Partners" />

      <section className="partners-hero hero two">
        <div className="row">
          <div className="inner">
            <div className="col text center" data-sal="slide-up" data-sal-delay="0">
              <h3>{data.hero_title.text}</h3>
              <div className="subtitle" dangerouslySetInnerHTML={{ __html: data.hero_subtitle.html }}></div>
            </div>

            <div className="col asset" data-sal="slide-left" data-sal-delay="300">
              <Img fluid={data.hero_image.fluid} alt={data.hero_title.text} />
            </div>
          </div>
        </div>

        <div className="row spacer">
          <div className="inner">
            <div className="col"></div>
            <div className="col"></div>
          </div>
        </div>
      </section>

      <section className="partner-groups">
        <div className="inner">
          <div className="partner-group">
            <h3>{data.strategic_alliances_headline.text}</h3>
            <div className="description" dangerouslySetInnerHTML={{ __html: data.strategic_alliances_description.html }}></div>

            <div className="partners">
              {data.strategic_alliances_partners.map((partner, ind) => {
                return <Partner data={partner} ind={ind} key={ind} />
              })}
            </div>
          </div>

          <div className="partner-group">
            <h3>{data.technology_partners_headline.text}</h3>
            <div className="description" dangerouslySetInnerHTML={{__html: data.technology_partners_description.html}}></div>

            <div className="partners">
              {data.technology_partners.map((partner, ind) => {
                return <Partner data={partner} ind={ind} key={ind} />
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PartnersPage

// Partner compontent
const Partner = ({ data, ind }) => {
  if (!data) return null

  const hasNoDescription = (!data.partner_description.html) ? 'no-description' : ''

  return (
    <div className="partner" data-sal="slide-up" data-sal-delay={`${150 + (ind++ * 50)}`}>
      <div className={`logo ${hasNoDescription}`}>
        <Img
          loading="eager"
          fluid={data.partner_logo.fluid}
          imgStyle={{ objectFit: "contain", objectPosition: "center" }}
          alt={data.partner_name.text}
        />
      </div>

      <div className="description" dangerouslySetInnerHTML={{__html: data.partner_description.html}}></div>
    </div>
  )
}